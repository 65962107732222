<template>
  <div class="d-flex flex-column vh-100">
    <rota-assign-job id="assign-job" :dates="selectedDates" />
    <sticky-header title="Rota" :subtitle="calendarDateTitle">
      <template #buttons>
        <b-button class="ml-2" variant="primary" v-if="dayView" @click="calDateClicked({start: moment(date).toDate(), end: moment(date).toDate()})"><fa-icon class="mr-2" icon="plus" /> Schedule</b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calView('timeGridDay')" variant="outline-primary">Day</b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calView('timeGridWeek')" variant="outline-primary">Week</b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calPrev" variant="outline-primary"><fa-icon icon="angle-left"/></b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calToday" variant="outline-primary">Today</b-button>
        <b-button class="d-none d-md-inline-block ml-2" @click.prevent="calNext" variant="outline-primary"><fa-icon icon="angle-right"/></b-button>
        <b-button class="ml-2" id="legend" variant="primary"><fa-icon class="mr-2" icon="users" /> Legend</b-button>
      </template>
    </sticky-header>
    <div class="bg-light border-bottom d-md-none d-flex flex-row justify-content-end px-4 py-2">
      <b-button class="ml-2" @click.prevent="calPrev" variant="outline-primary"><fa-icon icon="angle-left"/></b-button>
      <b-button class="ml-2" @click.prevent="calToday" variant="outline-primary">Today</b-button>
      <b-button class="ml-2" @click.prevent="calNext" variant="outline-primary"><fa-icon icon="angle-right"/></b-button>
    </div>
    <b-popover target="legend" triggers="click" placement="bottomleft">
      <div class="px-4 py-3">
        <p class="font-weight-bold h5 mb-2 pr-5">Team Colours</p>
        <p class="h4 mb-1 w-100" :key="ind" v-for="(team, ind) in teams"><span class="badge w-100" :style="{ backgroundColor: team.color, color: 'white' }">{{ team.name }}</span></p>
      </div>
    </b-popover>
    <div class="flex-fill p-3">
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <template v-if="dayView">
        <h3 class="mb-2">{{ moment(date).format('DD/MM/Y') }}</h3>
        <div
          class="d-flex flex-row dayview-width"
        >
          <div
            v-for="(teams, key) in calendarOptions.eventsDaily"
            :key="key"
            class="mr-2"
            :style="{
             'width': '300px',
             'min-width': '300px',
            }"

          >
            <h4>{{ teams.team.name }}</h4>
            <div
              v-for="event in teams.jobs"
              :key="event.id"
              class="border p-2 mb-2"
              :style="{
                'cursor': 'pointer',
                'border-radius': '5px',
                'color': event.textColor,
                'border-color': event.borderColor,
                'background-color': event.backgroundColor,
              }"
              @click="$router.push('rotas/update/' + event.id)"
            >
              <p class="mb-0">{{ event.title }}</p>
              <p class="mb-0">{{ moment(event.start).format('HH:mm') }} - {{ moment(event.end).format('HH:mm') }} </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <full-calendar ref="calendar" :options="calendarOptions" v-if="!loading" />
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import get from 'lodash/get'
import map from 'lodash/map'
import flatten from 'lodash/flatten'
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import RotaAssignJob from './Modals/RotaAssignJob.vue'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: { RotaAssignJob, FullCalendar, StickyHeader },
  computed: {
    ...mapState('rotas', ['data', 'loading']),
    ...mapState('auth', ['user']),
    selectedAbsence() {
      return this.data && this.data.find(a => parseInt(this.selectedAbsenceId) === a.id) || {};
    }
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'assign-job') {
        this.load(this.date);
      }
    })

    this.calToday();
  },
  data() {
    return {
      teams: [],
      date: sessionStorage.getItem("rota:date") ? moment(sessionStorage.getItem("rota:date")) : moment(),
      selectedDates: sessionStorage.getItem("rota:selectedDates") ?? {
        start: moment(),
        end: moment(),
      },

      dayView: sessionStorage.getItem("rota:dayView") ?? false,

      selectedAbsenceId: null,
      calendarDateTitle: 'Loading',
      calendarOptions: {
        // Plugins, Themes and View
        plugins: [ bootstrapPlugin, timeGridPlugin, interactionPlugin ],
        businessHours: [{
            daysOfWeek: [0, 1, 2, 3, 4, 5 ,6],
            startTime: '09:00',
            endTime: '17:00',
        }],
        initialView: 'timeGridWeek',
        themeSystem: 'bootstrap',
        headerToolbar: false,
        nowIndicator: true,
        allDaySlot: false,
        weekends: true,
        height: "100%",

        editable: true,
        selectable: true,
        selectMirror: true,
        select: this.calDateClicked,
        events: [],
        eventsDaily: [],

        defaultDate: this.date,

        eventResize: this.calEventResize,
        eventDrop: this.calEventDrop,

        eventClick: this.onClickEvent,

        dayHeaderContent(date) {
          return moment(date.date).format('ddd DD/MM')
        }
      }
    }
  },
  methods: {
    ...mapActions('rotas', ['fetch', 'updateOrCreate']),
    moment,
    calDateClicked({ start, end }) {
        this.selectedDates = { start, end };
        this.$router.push({ name: 'rotas.assign' });
    },
    async calEventResize(info) {
      const rota = this.data.find(r => r.jobs.findIndex(e => e.id === parseInt(info.event.id)) > -1)
      const evt = rota.jobs.find(e => e.id === parseInt(info.event.id))

      if (evt) {
        await this.updateOrCreate({
          job_id: info.event.id,
          team_id: rota.team.id,
          start_date: moment(info.event.start).format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(info.event.end).format('YYYY-MM-DD HH:mm:ss'),
        })
      }
    },
    async calEventDrop(info) {
      const rota = this.data.find(r => r.jobs.findIndex(e => e.id === parseInt(info.event.id)) > -1)
      const evt = rota.jobs.find(e => e.id === parseInt(info.event.id))

      if (evt) {
        await this.updateOrCreate({
          job_id: info.event.id,
          team_id: rota.team.id,
          start_date: moment(info.event.start).format('YYYY-MM-DD HH:mm:ss'),
          end_date: moment(info.event.end).format('YYYY-MM-DD HH:mm:ss'),
        })
      }
    },
    calNext() {
      this.date = this.date.add(1, (this.calendarOptions.initialView == 'timeGridDay') ? 'day' : 'week');

      sessionStorage.setItem("rota:date", this.date)
      this.load(this.date).then(() => {
        this.calendarDateTitle = this.date.format('MMMM YYYY');
        this.$refs.calendar.getApi().gotoDate(this.date.toDate());
      });
    },
    calPrev() {
      this.date = this.date.add(-1, (this.calendarOptions.initialView == 'timeGridDay') ? 'day' : 'week');

      sessionStorage.setItem("rota:date", this.date)
      this.load(this.date).then(() => {
        this.calendarDateTitle = this.date.format('MMMM YYYY');
        this.$refs.calendar.getApi().gotoDate(this.date.toDate());
      });
    },
    calToday() {
      this.date = moment();

      sessionStorage.setItem("rota:date", this.date)
      this.load(this.date).then(() => {
        this.calendarDateTitle = this.date.format('MMMM YYYY');
        this.$refs.calendar.getApi().gotoDate(this.date.toDate());
      });
    },
    calView(view) {
      this.dayView = (view == 'timeGridDay');

      this.calendarOptions.initialView = view;
      this.$refs.calendar.getApi().changeView(view);
    },
    async load(date) {
      const after = moment(date).startOf('week').format('YYYY-MM-DD');
      const before = moment(date).endOf('week').format('YYYY-MM-DD');
      const userId = this.user.type !== 'staff' ? this.user.id : null;

      await this.fetch({ after, before, userId });

      return;
    },
    onClickEvent(info) {
      const id = info.event.id;

      this.$router.push('rotas/update/' + id);
    }
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    data(data) {
      const events = map(data, rota => {
        if (!this.teams.find(t => rota.team.id === t.id)) {
          this.teams.push(rota.team);
        }

        return {
          jobs: map(rota.jobs, job => ({
            id: job.id,
            title: `${get(job, 'customer.name') || get(job, 'property.name')}'s Cleaning Job`,
            start: moment(job.started_at).toDate(),
            end: moment(job.started_at).add(job?.duration_min ?? 0, 'minutes').toDate(),
            allDay: false,

            backgroundColor: rota.team.color,
            borderColor: rota.team.color,
            textColor: 'white',
          })),
        };
      });

      let jobsByTeam = {};
      // Take the rota of all jobs and break them up into their teams
      data.forEach((rota) => {
        rota.jobs.forEach((job) => {
          if (!moment(this.date).isSame(moment(job.started_at), 'day')) return;

          if (!jobsByTeam[rota.team_id]) {
            jobsByTeam[rota.team_id] = {
              'team': rota.team,
              'jobs': [],
            };
          }

          jobsByTeam[rota.team_id].jobs.push({
            id: job.id,
            title: `${get(job, 'customer.name') || get(job, 'property.name')}'s Cleaning Job`,
            start: moment(job.started_at).toDate(),
            end: moment(job.started_at).add(job?.duration_min ?? 0, 'minutes').toDate(),
            allDay: false,

            backgroundColor: rota.team.color,
            borderColor: rota.team.color,
            textColor: 'white',
          });

          jobsByTeam[rota.team_id].jobs.sort((a, b) => {
            return moment(a.start) - moment(b.start);
          })
        })
      });

      this.calendarOptions = {
        ...this.calendarOptions,
        eventsDaily: jobsByTeam,
        events: flatten(map(events, 'jobs')),
      };
    },
    selectedDates(data) {
      sessionStorage.setItem("rota:date", data);
    },
    dayView(data) {
      sessionStorage.setItem("rota:dayView", data);
    }
  }
}
</script>

<style>
.fc-button {
  border-radius: 0;
}

.fc-button-primary {
  background: #1A1A1A;
}

.fc-timeGridDay-view .fc-timegrid-event-harness {
  max-width: 20% !important;
}

@media (min-width: 768px) {
  .dayview-width {
    overflow-x: auto;
    width: calc(100vw - 351px);
  }
}
@media (max-width: 768px) {
  .dayview-width {
    overflow-x: auto;
  }
}

</style>
