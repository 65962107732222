<template>
  <resource-search @delete="() => this.$emit('delete')" @input="id => this.$emit('input', id)" module="jobs" :show-delete="showDelete" :fetch-params="extraFetchParams" :value="value" @selected="onChangeSelected" :eager="this.$props.eager">
    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0">{{ (result.customer && result.customer.name) || (result.property && result.property.name) }}</p>
      <p class="text-muted mb-0"><small>{{ moment().startOf('day').add(result.duration_min, 'minutes').format('H [hours] m [minutes]') }} &bull; Planned Day: {{ moment(result.started_at).format('DD/MM/yyyy') }} &bull; {{ result.property_id ? 'Created from booking' : 'Not created from booking'}}</small></p>
    </template>
    <template #selected="{ selected }">
      <p class="font-weight-bold mb-0">{{ (selected.customer && selected.customer.name) || (selected.property && selected.property.name) }}</p>
      <p class="text-muted mb-0"><small>{{ moment().startOf('day').add(selected.duration_min, 'minutes').format('H [hours] m [minutes]') }} &bull; Planned Day: {{ moment(selected.started_at).format('DD/MM/yyyy') }} &bull; {{ selected.property_id ? 'Created from booking' : 'Not created from booking'}}</small></p>
    </template>
  </resource-search>
</template>

<script>
import moment from 'moment'
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    onlyUnassigned: Boolean,
    incomplete: {
      type: Boolean,
      default: false,
    },
    eager: {
      type: Boolean,
      default: false,
    },
    value: Number,
  },
  computed: {
    extraFetchParams() {
      return {
        onlyUnassigned: this.onlyUnassigned ? true : undefined,
        complete: this.incomplete ? 'Incomplete' : undefined,
        perPage: 100
      };
    }
  },
  methods: {
    moment,
    onChangeSelected(data) {
      //data.duration_min
      this.$emit('selected', data);
    }
  },
}
</script>

<style>

</style>
