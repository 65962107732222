<template>
  <resource-search @delete="() => this.$emit('delete')" @input="id => this.$emit('input', id)" module="teams" :show-delete="showDelete" :fetch-params="extraFetchParams" :value="value">
    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0">{{ result.name }}</p>
      <p class="text-muted mb-0"><small>{{ result.members.length }} members</small></p>
    </template>

    <template #selected="{ selected }">
      <p class="font-weight-bold mb-0">{{ selected.name }}</p>
      <p class="text-muted mb-0"><small>{{ selected.members.length }} member{{ selected.members.length !== 1 ? 's' : ''}}</small></p>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'

export default {
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    type: String,
    value: Number,
  },
  computed: {
    extraFetchParams() {
      if (this.type) {
        return { type: this.type };
      }

      return {};
    }
  }
}
</script>

<style>

</style>
