<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" title="Assign New Job to Rota" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Job" :invalid-feedback="validationErrors.job_id" :state="!validationErrors.job_id">
                <job-search v-model="editedRotaItem.job_id" @selected="onJobSelected" only-unassigned incomplete eager />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Team" :invalid-feedback="validationErrors.team_id" :state="!validationErrors.team_id">
                <team-search v-model="editedRotaItem.team_id" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Date" :invalid-feedback="validationErrors.date" :state="!validationErrors.date">
                <b-form-datepicker v-model="editedRotaItem.date" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Start Time" :invalid-feedback="validationErrors.start_time" :state="!validationErrors.start_time">
                <b-form-timepicker v-model="editedRotaItem.start_time" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="End Time" :invalid-feedback="validationErrors.end_time" :state="!validationErrors.end_time">
                <b-form-timepicker v-model="editedRotaItem.end_time" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12" v-if="!$route.params.rotaId">
              <b-form-group label="Checklist Template" :invalid-feedback="validationErrors.end_time" :state="!validationErrors.end_time">
                <b-form-select id="template" v-model="editedRotaItem.template_id" :options="templates" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          @click.prevent="deleteRota"
          variant="danger"
          class="mr-auto"
        >Delete</b-button>

        <b-button
          v-if="editedRotaItem?.job_id"
          :to="{ name: 'jobs.details', params: { jobId: editedRotaItem?.job_id } }"
          variant="primary"
        >View Job</b-button>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveRota" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">Assign</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import JobSearch from '../../components/JobSearch'
import TeamSearch from '../../components/TeamSearch'
import { mapActions, mapState } from 'vuex'

export default {
  components: { JobSearch, TeamSearch },
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      selectedJob: {},
      editedRotaItem: {
        date: moment().toDate(),
        start_time: moment().format('HH:mm:ss'),
        end_time: moment().format('HH:mm:ss'),
        team_id: null,
        job_id: null,
        template_id: null,
      },
      templates: {},
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    dates: Object,
    id: String,
  },
  async mounted() {
    await this.setup();
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('checklistTemplates', ['data']),
  },
  methods: {
    ...mapActions('checklistTemplates', ['fetch']),
    ...mapActions('rotas', ['fetchSingle', 'updateOrCreate']),
    ...mapActions('jobs', ['fetchSingle', 'delete']),
    async setup () {
      if (this.$route.params.rotaId && this.editedRotaItem) {
        this.loading = true;

        try {
          const editedRotaItem = await this.fetchSingle(this.$route.params.rotaId);

          this.editedRotaItem = {
            job_id: editedRotaItem.id,
            team_id: editedRotaItem?.rota?.team_id,
            date: editedRotaItem.started_at,
            start_time: moment(editedRotaItem.started_at).format('HH:mm:ss'),
            end_time: moment(editedRotaItem.started_at).add(editedRotaItem?.duration_min ?? 0, 'minutes').format('HH:mm:ss'),
          };
          this.loading = false;
        } catch (err) {
          this.error = "Can not load rota item. Please try again later.";
          this.loading = false;
        }
      }

      this.fetch().then(() => {
        this.data.forEach((item) => {
          this.templates[item.id] = item.name;
        })
      });
    },
    closeModal() {
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.editedRotaItem = {
        job_id: 0,
        team_id: 0,
        date: moment().toDate(),
        time: moment().format('HH:mm:ss'),
        start_time: undefined,
        end_time: undefined
      }
    },
    deleteRota() {
      if (!this.$route.params.rotaId) return;
      this.delete(this.$route.params.rotaId);
      this.closeModal();
    },
    saveRota() {
      this.saving = true;
      this.updateOrCreate({
        job_id: this.editedRotaItem.job_id,
        team_id: this.editedRotaItem.team_id,
        template_id: this.editedRotaItem?.template_id,
        start_date: moment(this.editedRotaItem.date).startOf('day').add(moment.duration(this.editedRotaItem.start_time)).format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(this.editedRotaItem.date).startOf('day').add(moment.duration(this.editedRotaItem.end_time)).format('YYYY-MM-DD HH:mm:ss'),
      }).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          err.response.json().then(json => {
            this.validationErrors = json.errors;
          });
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    },
    onJobSelected(data) {
      this.editedRotaItem.end_time = moment(this.editedRotaItem.date).startOf('day').add(moment.duration(this.editedRotaItem.start_time)).add(data?.duration_min ?? 0, 'minutes').format('HH:mm:ss')
    }
  },
  watch: {
    dates({ start, end }) {
      this.editedRotaItem.date = moment(start).toDate();
      this.editedRotaItem.start_time = moment(start).format('HH:mm:ss');
      this.editedRotaItem.end_time = moment(end).format('HH:mm:ss');
    },
    '$route.params.rotaId': {
      handler: function() {
        this.setup();
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
